import fetch from "auth/FetchInterceptor";

const JwtAuthService = {};

JwtAuthService.sendOtp = function (phone) {
  return fetch({
    url: "send-otp",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: { phone },
  })
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
};
JwtAuthService.verifyOtp = function (phone, otp) {
  return fetch({
    url: "verify-otp",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: { phone, otp },
  })
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
};

JwtAuthService.login = function (email, password) {
  return fetch({
    url: "admin/login",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: { email, password },
  }).then((res) => ({ res }));
  // .catch((err) => "hi");
};

JwtAuthService.saveToken = function (data) {
  return fetch({
    url: "save-token",
    method: "post",
    data,
  })
    .then((res) => ({ res }))
    .catch((err) => ({ err }));
};
JwtAuthService.updatePassword = function (data) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  console.log("this is the User ", { user });
  data.email = user.email;
  return fetch({
    url: `admin/reset-password`,
    method: "post",
    data,
  });
};

export default JwtAuthService;
