import {
  AppstoreOutlined,
  BoldOutlined,
  BookOutlined,
  TrophyOutlined,
  HomeOutlined,
  CalculatorFilled,
  ContactsOutlined,
  GoogleOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const extraNavTree = [];

const appsNavTree = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboards`,
    breadcrumb: false,
    icon: HomeOutlined,
    submenu: [
      {
        key: "home",
        path: `${APP_PREFIX_PATH}/home/edit`,
        title: "Home",
        icon: HomeOutlined,
        breadcrumb: true,
        submenu: [],
        permission: "brand_view",
      },
      {
        key: `${APP_PREFIX_PATH}/google-analytic/edit`,
        path: `${APP_PREFIX_PATH}/google-analytic/edit`,
        title: "Analytic",
        icon: GoogleOutlined,
        breadcrumb: true,
        submenu: [],
        permission: "analytic_view",
      },
      {
        key: "about-us",
        path: `${APP_PREFIX_PATH}/about-us/edit`,
        title: "About Us",
        icon: TrophyOutlined,
        breadcrumb: true,
        submenu: [],
        permission: "brand_view",
      },
      {
        key: "blogs",
        path: `${APP_PREFIX_PATH}/blogs/list`,
        title: "Blogs",
        icon: BookOutlined,
        breadcrumb: true,
        submenu: [],
        permission: "blog_view",
      },
      {
        key: "pricing",
        path: `${APP_PREFIX_PATH}/pricing/list`,
        title: "Pricing",
        icon: BookOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        key: "services",
        path: `${APP_PREFIX_PATH}/services/list`,
        title: "Services",
        icon: BoldOutlined,
        breadcrumb: true,
        submenu: [],
        permission: "brand_view",
      },
      {
        key: "shop",

        title: "Shop",
        icon: AppstoreOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "shop-category",
            path: `${APP_PREFIX_PATH}/shop-category/list`,
            title: "Category",
            icon: AppstoreOutlined,
            breadcrumb: true,
            submenu: [],
            permission: "category_view",
          },
          {
            key: "shop-products",
            path: `${APP_PREFIX_PATH}/shop-products/list`,
            title: "Products",
            icon: AppstoreOutlined,
            breadcrumb: true,
            submenu: [],
            permission: "category_view",
          },
          {
            key: "product-orders",
            path: `${APP_PREFIX_PATH}/product-orders/list`,
            title: "Orders",
            icon: AppstoreOutlined,
            breadcrumb: true,
            submenu: [],
            permission: "category_view",
          },
        ],
        permission: "category_view",
      },
      {
        key: "calculator",
        path: `${APP_PREFIX_PATH}/calculator/list`,
        title: "Price Calculator",
        icon: CalculatorFilled,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "contact-us",
        path: `${APP_PREFIX_PATH}/contact-us/list`,
        title: "Contact Us",
        icon: ContactsOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...appsNavTree, ...extraNavTree];

export default navigationConfig;
